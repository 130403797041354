import React from 'react'
import { graphql, navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Hero from '../../components/hero'
import SEO from '../../components/seo'

export const pageQuery = graphql`
  query ReportQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allReports {
      edges {
        node {
          id
          year
          data {
            languages {
              description
              file_url {
                download_link
                original_name
              }
              release_date
              locale
            }
          }
        }
      }
    }
    allFinanceStatement {
      edges {
        node {
          id
          year
          data {
            languages {
              description
              file_url {
                download_link
                original_name
              }
              release_date
              locale
            }
          }
        }
      }
    }
    allInformationDisclosure {
      edges {
        node {
          id
          year
          data {
            languages {
              description
              file_url {
                download_link
                original_name
              }
              release_date
              locale
            }
          }
        }
      }
    }
  }
`

interface ReportPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        siteUrl: string
      }
    }
    allReports: {
      edges: [
        {
          node: {
            id: string
            year: string
            data: [
              {
                languages: [
                  {
                    file_url: {
                      download_link: string
                      original_name: string
                    }
                    release_date: string
                    locale: string
                    description: string
                  }
                ]
              }
            ]
          }
        }
      ]
    }
    allFinanceStatement: {
      edges: [
        {
          node: {
            id: string
            year: string
            data: [
              {
                languages: [
                  {
                    file_url: {
                      download_link: string
                      original_name: string
                    }
                    release_date: string
                    locale: string
                    description: string
                  }
                ]
              }
            ]
          }
        }
      ]
    }
    allInformationDisclosure: {
      edges: [
        {
          node: {
            id: string
            year: string
            data: [
              {
                languages: [
                  {
                    file_url: {
                      download_link: string
                      original_name: string
                    }
                    release_date: string
                    locale: string
                    description: string
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  }
}

const ReportPage = (props: ReportPageProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = React.useState({
    title: 'Report',
    description: 'Redefining your customer experiences with Jasnita CX solutions.',
  })
  const { allReports, allFinanceStatement, allInformationDisclosure } = props.data
  return (
    <>
      <SEO title={content.title} description={content.description} />

      <Hero type="investor">
        <h1 className="uk-heading-small">{content.title}</h1>
        <p>{content.description}</p>
      </Hero>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <aside className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body uk-width-1-1 uk-visible@m">
                <ul
                  className="uk-nav-default uk-nav-center uk-nav-parent-icon"
                  uk-nav=""
                  uk-switcher="connect: #annual-report-section; active: 0; swiping: false"
                >
                  {allReports.edges.map((report, index) => (
                    <li key={report.node.id} className={index === 0 ? `uk-active` : undefined}>
                      <a href="#">Annual Report {report.node.year}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="uk-hidden@m">
                <nav className="uk-navbar-transparent" uk-navbar="mode: click">
                  <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                      <li className="uk-active">
                        <a href="#">Year</a>
                        <div className="uk-navbar-dropdown">
                          <ul
                            className="uk-nav uk-navbar-dropdown-nav"
                            uk-switcher="connect: #annual-report-section; active: 0; swiping: false"
                          >
                            {allReports.edges.map((report, index) => (
                              <li key={report.node.id} className={index === 0 ? `uk-active` : undefined}>
                                <a href="#">{report.node.year}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </aside>
            <ul className="uk-switcher uk-width-expand@m" id="annual-report-section">
              {allReports.edges.map((report, index) => (
                <li key={report.node.id} className={index === 0 ? `uk-active` : undefined} uk-switcher-item={index}>
                  <h2 className="uk-article-subtitle">Annual Report {report.node.year}</h2>
                  <table className="uk-table uk-table-justify uk-table-divider">
                    <thead>
                      <tr>
                        <th className="uk-width-small">Release Date</th>
                        <th className="uk-table-expand">Description</th>
                        <th className="uk-width-small"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {report.node.data.map((file, index) =>
                        file.languages.map((reportList, index) => {
                          if (reportList.locale === i18n.language) {
                            return (
                              <tr key={index}>
                                <td>{reportList.release_date}</td>
                                <td>{reportList.description}</td>
                                <td>
                                  <a
                                    className="uk-button uk-button-default"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      navigate(reportList.file_url.download_link)
                                    }}
                                  >
                                    Download
                                  </a>
                                </td>
                              </tr>
                            )
                          }
                        })
                      )}
                    </tbody>
                  </table>
                  {/* <p className="uk-article-meta">Last updated on 12 April 2012.</p> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <div className="uk-container">
        <hr className="uk-divider-icon" />
      </div>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <aside className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body uk-width-1-1 uk-visible@m">
                <ul
                  className="uk-nav-default uk-nav-center uk-nav-parent-icon"
                  uk-nav=""
                  uk-switcher="connect: #financial-report-section; active: 0; swiping: false"
                >
                  {allFinanceStatement.edges.map((report, index) => (
                    <li key={report.node.id} className={index === 0 ? `uk-active` : undefined}>
                      <a href="#">Financial Statement {report.node.year}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="uk-hidden@m">
                <nav className="uk-navbar-transparent" uk-navbar="mode: click">
                  <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                      <li className="uk-active">
                        <a href="#">Year</a>
                        <div className="uk-navbar-dropdown">
                          <ul
                            className="uk-nav uk-navbar-dropdown-nav"
                            uk-switcher="connect: #financial-report-section; active: 0; swiping: false"
                          >
                            {allFinanceStatement.edges.map((report, index) => (
                              <li key={report.node.id} className={index === 0 ? `uk-active` : undefined}>
                                <a href="#">{report.node.year}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </aside>
            <ul className="uk-switcher uk-width-expand@m" id="financial-report-section">
              {allFinanceStatement.edges.map((report, index) => (
                <li key={report.node.id} className={index === 0 ? `uk-active` : undefined} uk-switcher-item={index}>
                  <h2 className="uk-article-subtitle">Financial Statement {report.node.year}</h2>
                  <table className="uk-table uk-table-justify uk-table-divider">
                    <thead>
                      <tr>
                        <th className="uk-width-small">Release Date</th>
                        <th className="uk-table-expand">Description</th>
                        <th className="uk-width-small"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {report.node.data.map((file, index) =>
                        file.languages.map((reportList, index) => {
                          if (reportList.locale === i18n.language) {
                            return (
                              <tr key={index}>
                                <td>{reportList.release_date}</td>
                                <td>{reportList.description}</td>
                                <td>
                                  <a
                                    className="uk-button uk-button-default"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      navigate(reportList.file_url.download_link)
                                    }}
                                  >
                                    Download
                                  </a>
                                </td>
                              </tr>
                            )
                          }
                        })
                      )}
                    </tbody>
                  </table>
                  {/* <p className="uk-article-meta">Last updated on 12 April 2012.</p> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <div className="uk-container">
        <hr className="uk-divider-icon" />
      </div>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <aside className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body uk-width-1-1 uk-visible@m">
                <ul
                  className="uk-nav-default uk-nav-center uk-nav-parent-icon"
                  uk-nav=""
                  uk-switcher="connect: #information-disclosure-report-section; active: 0; swiping: false"
                >
                  {allInformationDisclosure.edges.map((report, index) => (
                    <li key={report.node.id} className={index === 0 ? `uk-active` : undefined}>
                      <a href="#">Information Disclosure {report.node.year}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="uk-hidden@m">
                <nav className="uk-navbar-transparent" uk-navbar="mode: click">
                  <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                      <li className="uk-active">
                        <a href="#">Year</a>
                        <div className="uk-navbar-dropdown">
                          <ul
                            className="uk-nav uk-navbar-dropdown-nav"
                            uk-switcher="connect: #information-disclosure-report-section; active: 0; swiping: false"
                          >
                            {allInformationDisclosure.edges.map((report, index) => (
                              <li key={report.node.id} className={index === 0 ? `uk-active` : undefined}>
                                <a href="#">{report.node.year}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </aside>
            <ul className="uk-switcher uk-width-expand@m" id="information-disclosure-report-section">
              {allInformationDisclosure.edges.map((report, index) => (
                <li key={report.node.id} className={index === 0 ? `uk-active` : undefined} uk-switcher-item={index}>
                  <h2 className="uk-article-subtitle">Information Disclosure {report.node.year}</h2>
                  <table className="uk-table uk-table-justify uk-table-divider">
                    <thead>
                      <tr>
                        <th className="uk-width-small">Release Date</th>
                        <th className="uk-table-expand">Description</th>
                        <th className="uk-width-small"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {report.node.data.map((file, index) =>
                        file.languages.map((reportList, index) => {
                          if (reportList.locale === i18n.language) {
                            return (
                              <tr key={index}>
                                <td>{reportList.release_date}</td>
                                <td>{reportList.description}</td>
                                <td>
                                  <a
                                    className="uk-button uk-button-default"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      navigate(reportList.file_url.download_link)
                                    }}
                                  >
                                    Download
                                  </a>
                                </td>
                              </tr>
                            )
                          }
                        })
                      )}
                    </tbody>
                  </table>
                  {/* <p className="uk-article-meta">Last updated on 12 April 2012.</p> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReportPage
